import { graphql, useStaticQuery } from "gatsby"
import React, { FC } from "react"
import { Helmet } from "react-helmet"

interface seoProps {
  lang: string
  title: string
  description: string
  keywords: string
  image?: string
  meta?: any
  schemaMarkup?: any
}

// Seo data component
const SEO: FC<seoProps> = ({
  lang = "en",
  title,
  description,
  image,
  keywords,
  meta = [],
  schemaMarkup,
}) => {
  const { site } = useStaticQuery(
    graphql`
      query {
        site {
          siteMetadata {
            title
            description
            organization
          }
        }
      }
    `
  )

  const metaDescription = description || site.siteMetadata.description

  return (
    <Helmet
      title={title}
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `keywords`,
          content: keywords,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `image`,
          content: image,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: site.siteMetadata.organization,
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
        {
          name: "content-language",
          content: lang,
        }
      ].concat(meta)}
    >
      {/* Add schema markup as stringified JSON */}
      {schemaMarkup && (
        <script type="application/ld+json">
          {JSON.stringify(schemaMarkup)}
        </script>
      )}
    </Helmet>
  )
}

export default SEO
